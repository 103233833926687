var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "zg-section",
    { staticClass: "hero-cta", attrs: { width: "xl", "no-y-padding": "" } },
    [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "row row--two-columns" }, [
          _c("div", { staticClass: "column" }, [
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.highlightTitle({ market: _vm.MARKET, text: _vm.title })
                ),
              },
            }),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.subTitle))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "usps row row--two-columns" },
              [
                _vm.usps
                  ? _c("zg-usps", {
                      attrs: { usps: _vm.usps, direction: "vertical" },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row hide-mobile" },
              [
                _vm.trustpilotData
                  ? _c("broker-hero-trustpilot", {
                      attrs: { data: _vm.trustpilotData },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "lazy-hydrate",
                { attrs: { never: "" } },
                [
                  _c("fragment", {
                    attrs: {
                      name: "consumerLoanCalculator",
                      "custom-query": _vm.fragmentCustomQuery,
                      "pre-render": !_vm.LOCAL,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row hide-desktop" },
                [
                  _vm.trustpilotData
                    ? _c("broker-hero-trustpilot", {
                        attrs: { data: _vm.trustpilotData },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }