<template>
  <div class="trustpilot-section">
    <zg-trustpilot
      :stars="data.stars"
      class="trustpilot"
    >
      <p v-html="data.scoreTemplate" />
    </zg-trustpilot>
  </div>
</template>

<script>

  export default {
    name: 'BrokerHeroTrustpilot',
    components: {
      ZgTrustpilot: () => import(/* webpackChunkName: 'zc/zg-trustpilot' */
        '@zc/components/ZgTrustpilot/ZgTrustpilot.vue')
    },
    props: {
      data: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .trustpilot-section {
    width: 70%;
    margin: rhythm() auto;

    @include mq(medium) {
      width: rem(300px);
      margin: 0;
    }
  }

  .trustpilot {
    flex-direction: column;

    ::v-deep .zg-flex {
      justify-content: flex-start;
    }
  }
</style>
