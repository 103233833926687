<template>
  <zg-section
    width="xl"
    class="hero-cta"
    no-y-padding
  >
    <div class="box">
      <div class="row row--two-columns">
        <div class="column">
          <h2 v-html="highlightTitle({ market: MARKET, text: title })" />
          <p>{{ subTitle }}</p>

          <div class="usps row row--two-columns">
            <zg-usps
              v-if="usps"
              :usps="usps"
              direction="vertical"
            />
          </div>

          <div class="row hide-mobile">
            <broker-hero-trustpilot
              v-if="trustpilotData"
              :data="trustpilotData"
            />
          </div>
        </div>

        <div class="column">
          <lazy-hydrate never>
            <fragment
              name="consumerLoanCalculator"
              :custom-query="fragmentCustomQuery"
              :pre-render="!LOCAL"
            />
          </lazy-hydrate>

          <div class="row hide-desktop">
            <broker-hero-trustpilot
              v-if="trustpilotData"
              :data="trustpilotData"
            />
          </div>
        </div>
      </div>
    </div>
  </zg-section>
</template>

<script>
  import LazyHydrate from 'vue-lazy-hydration'
  import { mapGetters } from 'vuex'
  import { highlightTitle } from '../../../../utils'
  import Fragment from '../../../elements/misc/Fragment'
  import BrokerHeroTrustpilot from './BrokerHeroTrustpilot'

  export default {
    name: 'BrokerHeroCtaVariation',
    components: {
      LazyHydrate,
      BrokerHeroTrustpilot,
      Fragment,
      ZgSection: () => import(/* webpackChunkName: 'zc/zg-section' */
        '@zc/components/ZgSection/ZgSection.vue'),
      ZgUsps: () => import(/* webpackChunkName: 'zc/zg-usps' */
        '@zc/components/ZgSection/ZgSection.vue')
    },
    props: {
      title: {
        type: String,
        required: false,
        default: null
      },
      subTitle: {
        type: String,
        required: false,
        default: null
      },
      usps: {
        type: Array,
        required: false,
        default: null
      },
      trustpilotData: {
        type: Object,
        required: false,
        default: () => {}
      }
    },
    computed: {
      ...mapGetters('router', ['getRoute']),
      ...mapGetters('translations', ['getLocale']),
      fragmentCustomQuery () {
        return {
          disclaimer: true,
          locale: this.getLocale,
          op_user_id: this.getRoute?.userId,
          ...(this.TESTCAFE ? { testcafe: true } : {})
        }
      }
    },
    methods: {
      highlightTitle
    }
  }
</script>

<style lang="scss" scoped>
  .hero-cta {
    padding: 0;
    background-color: transparent !important; /* stylelint-disable-line */

    @include mq(medium) {
      padding: rhythm(10) 0 !important; /* stylelint-disable-line */
    }
  }

  .row {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 rhythm();

    @include mq(medium) {
      flex-direction: row;
    }

    &:last-child {
      margin: 0;
    }

    &--two-columns {
      flex-direction: column;
      justify-content: space-between;

      @include mq(medium) {
        flex-direction: row;
      }
    }
  }

  .column {
    position: relative;
    width: 100%;
    margin: 0 0 rhythm();

    @include mq(medium) {
      width: calc(50% - #{rhythm()});
      margin: 0;
    }
  }

  .box {
    background-color: #fff;
    padding: rhythm(small);
    margin: 0 (- rhythm(small));

    @include mq(medium) {
      background-color: color(white, 0.95);
      border-radius: radius(box);
      box-shadow: box-shadow();
      padding: rhythm();
      margin: 0;
    }
  }

  h2 {
    color: #24385b;

    :deep(span) {
      color: #da8b2d;
    }
  }

  :deep(.zg-usps) {
    font-size: 1rem;

    .icon {
      color: #da8b2d;
    }

    svg {
      fill: #da8b2d;
    }
  }

  .hide-mobile {
    @include mq($until: medium) {
      display: none;
    }
  }

  .hide-desktop {
    @include mq(medium) {
      display: none;
    }
  }
</style>
