var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trustpilot-section" },
    [
      _c(
        "zg-trustpilot",
        { staticClass: "trustpilot", attrs: { stars: _vm.data.stars } },
        [_c("p", { domProps: { innerHTML: _vm._s(_vm.data.scoreTemplate) } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }